import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ShopIdService {
	shopIdObs$ = new ReplaySubject<string | null>(1);
	private readonly _refreshDash$ = new BehaviorSubject<null>(null);
	readonly refreshDash$ = this._refreshDash$.asObservable();

	constructor() {}

	triggerRefreshDash() {
		this._refreshDash$.next(null);
	}
}
