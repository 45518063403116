import { Injectable } from "@angular/core";
import {
	Subject,
	debounceTime,
	lastValueFrom,
	share,
	take,
	timeout,
} from "rxjs";
import { ErrorCode } from "@storebase/utils-common";
import { environment } from "../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { GoogleUserService } from "../auth/google-user.service";
import { ShopIdService } from "../dash/shop-id.service";
import { DialogData } from "./error-reporting.component";
import { ActivityLogBody } from "@storebase/api-bridge";

@Injectable({
	providedIn: "root",
})
export class ErrorReportingService {
	private nanoid = import("nanoid").then((m) => m.nanoid);

	/** Session Id is same across multiple error reports. So we can track */
	private sessionIdP = this.nanoid.then((nanoid) => nanoid());

	private _errorAlert$ = new Subject<DialogData>();
	errorAlert$ = this._errorAlert$
		.asObservable()
		.pipe(share(), debounceTime(1500));

	private serializeError?: typeof import("serialize-error").serializeError;
	constructor(
		private readonly googleUser: GoogleUserService,
		private readonly shopId: ShopIdService,
		private readonly http: HttpClient,
	) {}

	async report(
		rawError: Error | HttpErrorResponse | unknown,
		options: {
			/**
			 * This message will be shown to the user and forwarded to the reporting api.
			 * If alert message is present, a blocking alert will be presented to user.
			 * Else only background reporting will be done.
			 */
			alertMessage?: string;

			/** Alert title will be displayed at the top of alert */
			alertTitle?: string;

			/** This message will be sent to the reporting api. Include additional details here */
			internalMessage?: string;
			/** Storebase custom error code */
			sbErrorCode?: ErrorCode;

			/** Add more details to the report */
			moreJson?: object;

			severity?:
				| "DEBUG"
				| "INFO"
				| "NOTICE"
				| "WARNING"
				| "ERROR"
				| "CRITICAL"
				| "ALERT"
				| "EMERGENCY";
		} = {},
	) {
		if (
			rawError &&
			typeof rawError === "object" &&
			"errorReportId" in rawError
		) {
			console.error("[error already reported] ");
			return;
		}

		const [user, shopId] = await Promise.all([
			lastValueFrom(this.googleUser.googleUser$.pipe(take(1), timeout(2000))),
			lastValueFrom(this.shopId.shopIdObs$.pipe(take(1), timeout(2000))),
		]).catch(async () => {
			return [null, null] as const;
		});

		const { alertMessage, sbErrorCode, alertTitle } = options;

		const error = rawError
			? rawError instanceof Error || rawError instanceof HttpErrorResponse
				? rawError
				: typeof rawError === "string" ||
					  (typeof rawError === "object" && "toString" in rawError) // eslint-disable-line no-mixed-spaces-and-tabs
					? new Error(String(rawError))
					: new Error("unknown")
			: new Error("no_error_attached");

		const {
			severity: detectedSeverity,
			sbErrorCode: detectedErrorCode,
			alertMessage: detectedAlertMessage,
			alertTitle: detectedAlertTitle,
			errorReportId: detectedErrorReportId,
			abort,
		} = this.getOptionsFromHttpError(error, options.moreJson);

		if (abort) {
			console.warn("[error reporting aborted]:" + detectedAlertMessage);
			return;
		}

		options.severity = options.severity || detectedSeverity || "ERROR";
		options.internalMessage = options.internalMessage || "n/a";

		const actualAlertMessage = alertMessage || detectedAlertMessage;

		const userAgent = window.navigator.userAgent;
		const osInfo = userAgent.match(
			/(Windows|Mac|Linux|Android|iOS|iPhone|iPad)/gi,
		);
		const os = osInfo?.[0] || "Unknown";

		const errorReportId: string =
			detectedErrorReportId || (await this.nanoid.then((nanoid) => nanoid()));

		const modifiedError = Object.assign(error, options, {
			sessionId: await this.sessionIdP,
			timestamp: new Date(),
			url: window.location.href,
			userId: user?.uid,
			shopId: shopId || undefined,
			userAgent,
			osName: os,
			fingerprint: `${window.location.href}#${error.name}#${error.message}#[${"stack" in error ? error.stack : ""}]`,
			eventType: "error",
			gcpProjectId: environment.firebase.projectId,
			siteName: environment.siteName,
			resourceType: "firebase_domain",
			domainName: environment.siteName,
			errorReportId,
			sbErrorCode: sbErrorCode || detectedErrorCode || "unhandled",
			...(!("stack" in error) && {
				stack: new Error("default_error_stack").stack,
			}),
		} satisfies Partial<ActivityLogBody>);

		this.serializeError =
			this.serializeError ||
			(await import("serialize-error").then((m) => m.serializeError));
		if (!this.serializeError) throw new Error("no_serialize_module");

		const serialized = this.serializeError(modifiedError);

		if (actualAlertMessage)
			this._errorAlert$.next({
				errorReportId,
				alertMessage: actualAlertMessage,
				sbErrorCode: sbErrorCode || detectedErrorCode || "unhandled",
				alertTitle: alertTitle || detectedAlertTitle,
				errorReport: serialized,
			});

		if (!environment.production)
			console.error("Error reporting log in dev mode:", serialized);

		const sendRequest$ = this.http.post(
			`${environment.mainServer.baseUrl}/activity-log`,
			serialized,
		);

		await lastValueFrom(sendRequest$.pipe(take(1))).catch((err) =>
			console.error("[error in error reporting]", err),
		);

		console.info("error reported");
	}

	private getOptionsFromHttpError(
		error?: HttpErrorResponse | Error,
		moreJson?: { request?: { urlWithParams: string } },
	): {
		severity?:
			| "DEBUG"
			| "INFO"
			| "NOTICE"
			| "WARNING"
			| "ERROR"
			| "CRITICAL"
			| "ALERT"
			| "EMERGENCY";
		sbErrorCode?: ErrorCode;
		alertMessage?: string;
		alertTitle?: string;
		abort?: boolean;
		errorReportId?: string;
	} {
		if (!error || !(error instanceof HttpErrorResponse)) return {};

		switch (error.status) {
			case 400:
			case 401:
			case 422: {
				return {
					sbErrorCode: "friction_point",
					severity: "NOTICE",
					errorReportId: error.error?.errorReportId,
				};
			}
			case 403: {
				return {
					errorReportId: error.error?.errorReportId,
					sbErrorCode: "no_access",
					severity: "ERROR",
					alertTitle: "Access denied",
					alertMessage:
						"You do not have access to some requested resources. Our engineers are checking the situation. " +
						(environment.production
							? ""
							: "Resource: " + moreJson?.request?.urlWithParams),
				};
			}
			case 429: {
				return {
					errorReportId: error.error?.errorReportId,
					abort: true,
					alertMessage: "Too many requests (code 429)",
				};
			}

			case 500:
			default: {
				return {
					errorReportId: error.error?.errorReportId,
					sbErrorCode: "internal",
					severity: "ERROR",
					alertMessage: "Some internal errors occurred",
					alertTitle: "Something went wrong",
				};
			}
		}
	}
}
